const navigation = () => {
  return [
    {
      sectionTitle: 'Recipe Management',
      action: 'read',
      path: '/section/recipe-management'
    },
    {
      title: 'Recipe Generator',
      path: '/gastroairecipe',
      icon: 'mdi:chef-hat',
      action: 'read'
    },
    {
      title: 'Component Generator',
      path: '/componentGenerator',
      icon: 'mdi:cog',
      action: 'read'
    },
    {
      title: 'Recipe Creator',
      path: '/recipeCreator',
      icon: 'mdi:food-variant',
      action: 'read'
    },
    {
      sectionTitle: 'Menu Creator',
      action: 'read',
      path: '/section/recipe-management'
    },
    {
      title: 'Menu Creator',
      path: '/menuCreator',
      icon: 'mdi:book-open-page-variant',
      action: 'read'
    },
    {
      sectionTitle: 'My Recipes',
      action: 'read',
      path: '/section/my-recipes'
    },
    {
      title: 'Recipe Gallery',
      path: '/recipeGallery',
      icon: 'mdi:book-open-page-variant',
      action: 'read'
    },
    {
      title: 'My Recipes',
      path: '/myRecipes',
      icon: 'mdi:food',
      action: 'read'
    },
    {
      sectionTitle: 'Pricing',
      action: 'read',
      path: '/section/pricing'
    },
    {
      title: 'Pricing',
      path: '/pricingPage',
      icon: 'mdi:currency-usd',
      action: 'read'
    }
  ]
}

export default navigation
