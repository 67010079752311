// ** Next Import
import Link from 'next/link'

// ** MUI Imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'

// ** Theme Config Import
import themeConfig from 'src/configs/themeConfig'

// ** Navigation Import
import Navigation from 'src/@core/layouts/components/horizontal/navigation' // Adjust the import path for Navigation component

const LinkStyled = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  marginRight: theme.spacing(4) // Adjust this value to change the space between the logo and the navigation
}))

const AppBarContent = props => {
  // ** Props
  const { appBarContent: userAppBarContent, appBarBranding: userAppBarBranding, navHidden, horizontalLayoutProps, settings } = props

  // ** Hooks
  const theme = useTheme()

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {userAppBarBranding ? (
        userAppBarBranding(props)
      ) : (
        <LinkStyled href='/'>
          <Box
            sx={{
              width: '160px',
              height: '88px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden'
            }}
          >
            <img src='/images/hellochef_logo.jpeg' alt='Logo' style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </Box>
          <Typography variant='h6' sx={{ ml: 2, fontWeight: 700, lineHeight: 1.2 }}>
            {themeConfig.templateName}
          </Typography>
        </LinkStyled>
      )}

      {/* Spacer Box */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Navigation Menu */}
      {!navHidden && horizontalLayoutProps.navMenu?.navItems && (
        <Box sx={{ flexGrow: 0 }}>
          <Navigation horizontalNavItems={horizontalLayoutProps.navMenu.navItems} settings={settings} />
        </Box>
      )}

      {/* Spacer Box */}
      <Box sx={{ flexGrow: 1 }} />

      {userAppBarContent ? userAppBarContent(props) : null}
    </Box>
  )
}

export default AppBarContent
