const typography = {
  fontFamily: [
    'Gotham SSm',
    'Inter',
    'sans-serif',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  h1: {
    fontWeight: 700,
    fontSize: '40px',
    letterSpacing: '-0.6px',
    lineHeight: 1.2
  },
  h2: {
    fontWeight: 500,
    fontSize: '32px',
    letterSpacing: '-0.4px',
    lineHeight: 1.2
  },
  h3: {
    fontWeight: 500,
    fontSize: '28px',
    letterSpacing: '-0.2px',
    lineHeight: 1.2
  },
  h4: {
    fontWeight: 500,
    fontSize: '24px',
    letterSpacing: '0px',
    lineHeight: 1.2
  },
  h5: {
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0px',
    lineHeight: 1.2
  },
  h6: {
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '0px',
    lineHeight: 1.2
  },
  subtitle1: {
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: 1.5
  },
  subtitle2: {
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: 1.5
  },
  body1: {
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: 1.5
  },
  body2: {
    fontSize: '12px',
    lineHeight: 1.429,
    letterSpacing: '0.15px'
  },
  button: {
    fontSize: '14px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    fontWeight: 500
  },
  caption: {
    fontSize: '12px',
    lineHeight: 1.25,
    letterSpacing: '0.4px'
  },
  overline: {
    fontSize: '10px',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  }
}

export default typography
