// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Firebase Imports
import { signOut, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth'

import { doc, setDoc, getDoc } from 'firebase/firestore'; // Import Firestore functions

// ** Firebase auth Import
import { auth, db } from 'src/configs/firebase' // Import Firestore instance

// ** Next Import
import { useRouter } from 'next/router'

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)

  // ** Hooks
  const router = useRouter()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        // If the user data exists in Firestore, set it in local state and local storage
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUser(userData)
          window.localStorage.setItem('userData', JSON.stringify(userData));
        }
      }
      setLoading(false);
    });

    // Return the unsubscribe function to clean up the subscription
    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider()
    try {
      const result = await signInWithPopup(auth, provider)
      const docRef = doc(db, 'users', result.user.uid);
      const docSnap = await getDoc(docRef);
      let userData;
      if (docSnap.exists()) {
        // If the user already exists, get their data
        userData = docSnap.data();
      } else {
        // If the user doesn't exist, create a new user in Firestore
        userData = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          role: 'admin' // Set default role to 'admin'

        };
        await setDoc(docRef, userData);
      }

      // Set user data in state and local storage
      setUser(userData)
      window.localStorage.setItem('userData', JSON.stringify(userData));

      // Redirect after successful sign in
      const redirectURL = router.query.returnUrl && router.query.returnUrl !== '/' ? router.query.returnUrl : '/'
      router.replace(redirectURL)
    } catch (error) {
      // Handle the error ...
      console.log(error);
    }
  }

  const handleFacebookLogin = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const docRef = doc(db, 'users', result.user.uid);
      const docSnap = await getDoc(docRef);
      let userData;
      if (docSnap.exists()) {
        userData = docSnap.data();
      } else {
        userData = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          role: 'admin'  // Default role, adjust as necessary
        };
        await setDoc(docRef, userData);
      }
      setUser(userData);
      window.localStorage.setItem('userData', JSON.stringify(userData));

      // Debugging the redirect URL
      console.log('Redirect URL:', router.query.returnUrl);

      // Redirect to the returnUrl or home if not specified or if it's the root
      const redirectURL = router.query.returnUrl && router.query.returnUrl !== '/' ? router.query.returnUrl : '/';
      console.log('Final Redirect URL:', redirectURL);
      router.push(redirectURL);
    } catch (error) {
      console.error('Facebook login failed:', error);
      // Optionally update state or call a function to handle errors
    }
  };


  const handleEmailLogin = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const docRef = doc(db, 'users', userCredential.user.uid);
      const docSnap = await getDoc(docRef);
      let userData;
      if (docSnap.exists()) {
        // If the user data exists, get their data
        userData = docSnap.data();
      } else {
        // If the user doesn't exist, create a new user in Firestore
        userData = {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          displayName: userCredential.user.displayName || '',
          role: 'admin' // Set default role to 'admin'
          // Use an empty string if displayName is not available
        };
        await setDoc(docRef, userData);
      }

      // Set user data in state and local storage
      setUser(userData);
      window.localStorage.setItem('userData', JSON.stringify(userData));

      // Redirect after successful login
      const redirectURL = router.query.returnUrl && router.query.returnUrl !== '/' ? router.query.returnUrl : '/';
      router.replace(redirectURL);
    } catch (error) {
      // Handle the error ...
      console.log(error);
    }
  }

  const handleLogout = async () => {
    await signOut(auth)
    setUser(null)
    window.localStorage.removeItem('userData');
    router.push('/login')
  }

  const handleRegister = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Check if the user data already exists in Firestore (unlikely in a registration flow)
      const docRef = doc(db, 'users', userCredential.user.uid);
      const docSnap = await getDoc(docRef);
      let userData;
      if (docSnap.exists()) {
        // If the user data exists, it means they are somehow already registered, fetch their data
        userData = docSnap.data();
      } else {
        // Since this is a new registration, assign a default role
        userData = {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          displayName: userCredential.user.displayName || '', // Handle cases where displayName might not be available
          role: 'admin' // Assign a default role at registration
        };
        // Store user data in Firestore
        await setDoc(docRef, userData);
      }

      // Set user data in state and local storage
      setUser(userData);
      window.localStorage.setItem('userData', JSON.stringify(userData));

      // Navigate to home page after successful registration
      router.push('/');
    } catch (error) {
      // Handle the error ...
      console.log(error);
    }
  }

  const generateChatbotToken = async (chatbotId) => {
    const chatbotToken = uuidv4(); // Assuming you're using uuid library
    const chatbotRef = doc(db, 'chatbots', chatbotId);
    await setDoc(chatbotRef, { token: chatbotToken }, { merge: true });
    return chatbotToken;
  };


  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider()
    try {
      const result = await signInWithPopup(auth, provider)

      const docRef = doc(db, 'users', result.user.uid);
      const docSnap = await getDoc(docRef);
      let userData;
      if (docSnap.exists()) {
        // If the user already exists, get their data
        userData = docSnap.data();
      } else {
        // If the user doesn't exist, create a new user in Firestore
        userData = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          role: 'admin' // This should be retrieved from your database or API, not hard-coded
        };
        await setDoc(docRef, userData);
      }

      // Set user data in state and local storage
      setUser(userData)
      window.localStorage.setItem('userData', JSON.stringify(userData));

      // After successful sign up, redirect the user to the homepage
      router.push('/')
    } catch (error) {
      // Handle the error ...
      console.log(error);
    }
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    generateChatbotToken,
    login: handleLogin,
    register: handleRegister,
    googleSignup: handleGoogleSignup,
    emailLogin: handleEmailLogin,
    logout: handleLogout,
    facebookLogin: handleFacebookLogin  // Add Facebook login method here

  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}


export { AuthContext, AuthProvider }
