import { AbilityBuilder, Ability } from '@casl/ability'

export const AppAbility = Ability

const defineRulesFor = role => {
  const { can, rules } = new AbilityBuilder(AppAbility)

  switch (role) {
    case 'customer':
      can(['read', 'update'], ['/home/', '/CreateTicket/', '/MyTickets/'])
      break

    case 'agent':
      can(['read', 'update'], ['/ViewAdminTickets/', '/dashboard/'])
      break

    case 'people':
      can(['read', 'manage', 'update'], ['/ViewAdminTickets/', '/dashboard/'])
      break

    case 'admin':
    case 'developer':
      can('manage', 'all')
      break

    default:
      break
  }

  return rules
}

export const buildAbilityFor = (role, subject) => {
  return new AppAbility(defineRulesFor(role, subject), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object.type
  })
}

export const defaultACLObj = {
  action: 'manage',
  subject: 'all'
}

export default defineRulesFor
