import { initializeApp, getApps } from 'firebase/app'
import { getAuth, updatePassword, EmailAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions' // Import getFunctions

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCdDvNZ66aipLY1RHfIvgh294jHlD5Qzcc",
  authDomain: "gastro69.firebaseapp.com",
  databaseURL: "https://gastro69-default-rtdb.firebaseio.com",
  projectId: "gastro69",
  storageBucket: "gastro69.appspot.com",
  messagingSenderId: "124852046176",
  appId: "1:124852046176:web:7e5e39b21995f15f52f605"
}

let firebaseApp
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig)
}

export const auth = getAuth()

export const db = getFirestore()

export const storage = getStorage()

// Initialize Firebase Functions
const functions = getFunctions(firebaseApp)

export { getAuth, updatePassword, EmailAuthProvider, functions }

export default firebaseApp
