const navigation = () => {
  return [

    {
      title: 'Recipe Generator',
      path: '/gastroairecipe',
      icon: 'mdi:chef-hat' // Updated to chef hat, assuming it's related to cooking
    },
    {
      title: 'Component Generator',
      path: '/componentGenerator',
      icon: 'mdi:chef-hat' // Updated to chef hat, assuming it's related to cooking
    },
    {
      title: 'My Recipes',
      path: '/recipeGallery',
      icon: 'mdi:book-open-page-variant' // Updated to a book icon, suggesting a collection of recipes
    },

    {
      title: 'Recipe Gallery',
      path: '/myRecipes',
      icon: 'mdi:book-open-page-variant' // Updated to a book icon, suggesting a collection of recipes
    },

    {
      title: 'Recipe Creator',
      path: '/recipeCreator',
      icon: 'mdi:book-open-page-variant' // Updated to a book icon, suggesting a collection of recipes
    },
    {
      title: 'Pricing',
      path: '/pricingPage',
      icon: 'mdi:money' // Updated to a book icon, suggesting a collection of recipes
    },

  ]
}

export default navigation
